
















import Vue from "vue";
import conf from '../conf';

export default Vue.extend({
    props: ['value', 'opts', 'submitted'],
    components: { PasswordStrength: () => import("@/directives/PasswordStrength.vue") },
    data() {
        return {
          validity: { userPassword: {}, userPassword2: {}, submitted: false },
          val: this.value,
          userPassword2: null,
        };
    },
    computed: {
      passwordPattern() {
          return this.opts?.pattern || '' // if empty, it will use https://zxcvbn-ts.github.io/zxcvbn/ to display warnings/suggestions about password strength
      },
      error_msg() {
          return conf.error_msg;
      },
    },
    watch: {
        value(val) {
            this.val = val;
        },
        val(val) {
            this.$emit('input', val);
        },
        submitted(b) {
            this.validity.submitted = b;
        },
    },
});

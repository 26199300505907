








































import { includes } from 'lodash';
import Vue from "vue";
import * as Ws from '../services/ws';
import { router } from '../router';
import genericAttr from '../attrs/genericAttr.vue';

export default Vue.extend({
   props: ['step'],
   components: { genericAttr },
   data() { 
       return {
            user: undefined,
            profile: undefined,
            profiles: undefined,
       };
   },
   computed: {
     allow_reuse() {
         return this.step.attrs.uid?.uiType === 'queryParamForActionPre';
     },
     title_in_list() {
         return "title_in_list" in this.labels ? this.labels.title_in_list : this.labels.title;
     },
     labels() {
         return this.step.step.labels || {}
     },
     searchUser_inputName() {
         return "step-" + this.step.id;
     },
   },
   watch: {
     profile(p) {
         this.gotoStep(this.user, p);
     },
   },
   methods: {
     people_search(token) {
         return Ws.people_search(this.step.id, token);
     },
     withUser(u) {
         const need_profile : Ws.StepAttrOption = this.step.attrs.profilename_to_modify;
         if (need_profile) {
             const oneOf = need_profile.oneOf.filter(function (choice) {
                 return includes(u.global_profilename, choice.const);
             });
             if (need_profile.optional) {
                 oneOf.unshift({ const: '', title: 'Créer un nouveau profil' })
             }
             if (oneOf.length === 1) {
                 this.gotoStep(u, oneOf[0].const);
             } else {
                 this.profiles = { ...need_profile, oneOf };
                 this.user = u;
             }
         } else {
             this.gotoStep(u);
         }
     },
     gotoStep(u, profilename = undefined) {
        router.push(`/${this.step.id}?uid=${u.uid}` + (profilename ? `&profilename_to_modify=${profilename}` : ''));
     },
  },  
});
























import Vue from "vue";

export default Vue.extend({
    props: ['value', 'opts'],
    data() {
        return {
            validity: { jpegPhoto: {} },
            val: this.value,
            doGet: null,
        };
    },
    watch: {
        value(val) {
            this.val = val;
        },
        val(val) {
            this.$emit('input', val);
        },
    },
});


















































import Vue from "vue";
import * as Helpers from '../services/helpers';
import * as Address from '../../../shared/address';
import CurrentLdapValue from './CurrentLdapValue.vue';

export default Vue.extend({
    props: ['value', 'ldap_value', 'opts'],
    components: { CurrentLdapValue },
    data() {
        return {
            validity: { country: {}, address_lines: {}, postalCode: {}, town: {} },
            ...Address.fromString(this.value),
            ldap_val: this.ldap_value && Address.fromString(this.ldap_value) || {},
            postalCode_modified: false,
        };
    },
    watch: {
        value(val) {
            if (val && val !== this.currentValue) Helpers.assign(this, Address.fromString(val));
        },
        currentValue(val) {
            this.$emit('input', val);
        },
        postalCode() {
            this.postalCode_modified = true
        },
        towns(l) {
            if (l && l.length === 1 && this.postalCode_modified) {
                this.town = l[0];
            }
        },
    },
    asyncComputed: {
        async towns() {
            const code = this.postalCode;
            return code?.match('^[0-9]{5}$') ? await Helpers.frenchPostalCodeToTowns(code) : [];
        },
    },
    computed: {
        currentValue() {
            return Address.toString(this);
        },
    },
});

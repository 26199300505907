



























import Vue from "vue";
import CurrentLdapValue from './CurrentLdapValue.vue';
import * as _ from 'lodash';

function init(val) {
    return val instanceof Array ? val : val ? [val] : [];
}

function array_move_elt(array, index: number, direction: -1 | 1) {
    array.splice(index + direction, 0, array.splice(index, 1)[0]);
}

export default Vue.extend({
    props: ['name', 'value', 'ldap_value', 'opts', 'stepName', 'v'],
    components: { CurrentLdapValue },
    data() {
        let val = init(this.value);
        if (val.length === 0 && !this.opts.optional) val.push('');
        return {
            validity: { [this.name]: {} },
            val,
            ldap_val: init(this.ldap_value),
            initial_val: [...val],
            currentLdapValue_shown: false,
        };
    },
    computed: {
        first_item_opts() { 
            return { ..._.pick(this.opts, 'title', 'labels', 'uiOptions', 'optional', 'readOnly', 'oneOf', 'oneOf_async'), ...this.opts.items };
        },
        item_opts() {
            return { ...this.first_item_opts, optional: true };
        },
        uiOptions() {
            return this.opts.uiOptions || {}
        },
    },
    watch: {
        value(val) {
            this.val = init(val);
        },
    },
    methods: {
        tellParent() {
            this.$emit('input', this.val);
        },
        set_item(i, v) {
            this.$set(this.val, i, v);
            this.tellParent();
        },
        array_action(name, i) {
                 if (name === "remove_item") this.val.splice(i, 1);
            else if (name === "move_up") array_move_elt(this.val, i, -1);
            else if (name === "move_down") array_move_elt(this.val, i, 1);
            else console.error("internal error: unknown array_action", name)
            this.tellParent();
        }
    
    },
});

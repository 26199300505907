

























import Vue from "vue";
import { mapValues, omitBy } from 'lodash'
import { formatValue } from "../../../shared/v_utils";
import * as Helpers from '../services/helpers';

export default Vue.extend({
    props: ['v_array', 'opts'],
    data() {
        const attrs = omitBy(this.opts.items.properties, (opts) => opts.uiHidden === true)
        return {
            attrs,
            attr_templates: mapValues(attrs, (opts, _) => {
                const template = opts.uiOptions?.readOnly__vue_template || opts.description
                return template && Vue.extend({ props: ['v', 'value'], template: "<div>" + template + "</div>" })
            })
        }
    },
    methods: {
        formatValue,
        export_csv(event) {
            const csv = Helpers.to_csv(this.v_array, this.attrs)
            event.target.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv)
        },
    },
})



















import Vue from "vue";
import * as Ws from '../services/ws';

export default Vue.extend({
    props: ['value', 'name', 'real_name', 'opts', 'v', 'stepName', 'array_allowed_actions'],
    data() {
        return {
          validity: !this.opts.readOnly && { [this.name]: {} },
          val: undefined,
        };
    },
    asyncComputed: {
        valueS() {
            return this.value && Ws.search(this.stepName, this.real_name || this.name, this.value, 1).then(l => l && l[0])
        },
    },
    watch: {
        valueS(val) {
            this.val = val;
        },
        val(val) {
            console.log("val changed", val);
            if (val) {
                this.$emit('input', val.const);
                if (this.opts.onChange) this.opts.onChange(this.v, val.const, val);
            }
        },
    },
    methods: {
        search(token) {
            return Ws.search(this.stepName, this.real_name || this.name, token, 10 + 1); // NB: searching one more item to detect "moreResults" case
        },
        formatting(e) { 
            return this.opts.formatting ? this.opts.formatting(e) : e && e.title;
        },
        formatting_html(e) {
            return this.opts.formatting_html ? this.opts.formatting_html(e) : this.formatting(e);
        },
        formatting_vue(e) {
            return e?.title && Vue.extend({ props: ['v'], template: "<div>" + e.title + "</div>" });
        },
    },
});

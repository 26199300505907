

















import Vue from 'vue'

export default Vue.extend({
  props: ['v', 'l'],

  components: { 'compare-users': () => import('./CompareUsers.vue') },

  methods: {
      merge(homonym) {
        this.$emit('merge', homonym);
      },
      ignore(homonym) {
          homonym.ignore = true
      }
  }
});

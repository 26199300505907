


















































import { tail } from "lodash";
import Vue from "vue";
import * as Ws from '../services/ws';

export default Vue.extend({
    props: [ 'attrs', 'forced_headers' ],
    data() {
        return {
            to_import: undefined,
            show_options: !!this.forced_headers,
            forced_headers_: (this.forced_headers || []).join(','),
            ignore_first_line: false,
        }
    },
    watch: {
        to_import_for_parent(val) { this.$emit("change", val) },
    },
    computed: {
        to_import_for_parent() {
            if (!this.to_import) return undefined;

            let { fields, lines } = this.to_import || {}
            if (this.ignore_first_line) lines = tail(lines)
            return { fields, lines }
        }
    },
    methods: {
        css_import(file: File) {
          const forced_headers = this.forced_headers_ && this.forced_headers_.split(/[ ,;]/)
          Ws.csv2json(file, this.attrs, forced_headers).then(to_import => {
              this.to_import = to_import;
          });
        },
    },
});

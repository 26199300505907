



































import Vue from "vue";
import { sortBy, mapKeys } from 'lodash';

export default Vue.extend({
    props: ['imported', 'ordered_fields'],
    computed: {
        sorted_imported() {
            // display errors first, then moderation, ok, ignored
            return sortBy(this.imported, vr => vr.error ? 0 : vr.in_moderation ? 1 : vr.ignored ? 3 : 2);
        },
    },
    methods: {
        retry_v_on_error(v) {
            return { query: mapKeys(v, (_, k) => `default_${k}`) };
        },
    },
});


















import Vue from "vue";
import { ref, Ref } from 'vue';
import * as Helpers from '../services/helpers';

import Modal from '../directives/Modal.vue';

export default Vue.extend({
    components: { Modal },
    setup() {
        const active: Ref<Helpers.promise_defer<null>> = ref(undefined)
        const msg = ref("")
        const title = ref("")
        return {    
            open(labels) {
                msg.value = labels.msg
                title.value = labels.title || "Attention"
                active.value = Helpers.promise_defer()
                return active.value.promise
            },
            active, msg, title,
            cancel() {
                active.value.reject("cancel")
                active.value = undefined
            },
            ok() {
                active.value.resolve(null)
                active.value = undefined
            },
        }
    },
})
